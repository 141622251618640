import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './Addreport.css';
import axios from 'axios';

const UpdateReport = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Extracting the report ID from the URL

  const [statusMessage, setStatusMessage] = useState('');
  const [report, setReport] = useState({
    name: '',
    c_name: '',
    date: '',
    duration: '',
    type: '',
    quarter: '',
    venue: '',
    speaker: '',
    coordinator: '',
    target_aud: '',
    attend: '',
    expenditure: '',
    description: '',
    info: '',
    fb: '',
    lk: '',
    ig: '',
    yt: '',
    tw: '',
    img1: null,
    img2: null,
    img3: null,
  });

  useEffect(() => {
    // Fetch report data based on the report ID
    const fetchReportData = async () => {
      try {
        const response = await axios.get(`/api/reports/getReport/${id}`);
        const reportData = response.data;
        setReport(reportData);
      } catch (error) {
        console.error('Error fetching report data:', error);
      }
    };

    fetchReportData();
  }, [id]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setReport({ ...report, [name]: value });
  };

  const handleFileInput = (e) => {
    const { name, files } = e.target;
    setReport({ ...report, [name]: files[0] });
  };

  const updateReport = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('reportData', JSON.stringify(report));
    formData.append('images', report.img1);
    formData.append('images', report.img2);
    formData.append('images', report.img3);

    try {
   
      const res = await axios.put(`/api/add_reports/updateReport/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${sessionStorage.getItem('authToken')}`, // Include the JWT token in the headers
        },
        withCredentials: true,
      });

      if (res.ok) {
        // Report updated successfully
        setStatusMessage('Report updated successfully');
      } else {
        // Handle error
        setStatusMessage('Failed to update report');
      }
    } catch (error) {
      setStatusMessage('Error updating report');
      console.error('Error updating report:', error);
    }
  };

  return (
    <div className="addreports">
      <div className="section form-section">
        <div className="add-report-container">
          <h2>UPDATE REPORT</h2>
          <form onSubmit={updateReport}>
            <div className="section">
              <h3>BASIC INFO</h3>
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="name">Event Name</label>
                  <input onChange={handleInput} value={report.name} type="text" id="name" name="name" />
                </div>
                <div className="form-group">
                  <label htmlFor="c_name">Committee Name</label>
                  <input onChange={handleInput} value={report.c_name} type="text" id="c_name" name="c_name" />
                </div>
              </div>
              {/* Add more basic info fields */}
              <div className="form-group">
                <label htmlFor="date">Date</label>
                <input onChange={handleInput} value={report.date} type="date" id="date" name="date" />
              </div>
              <div className="form-group">
                <label htmlFor="duration">Duration</label>
                <input onChange={handleInput} value={report.duration} type="text" id="duration" name="duration" />
              </div>
              <div className="form-group">
                <label htmlFor="type">Type</label>
                <input onChange={handleInput} value={report.type} type="text" id="type" name="type" />
              </div>
              <div className="form-group">
                <label htmlFor="quarter">Quarter</label>
                <input onChange={handleInput} value={report.quarter} type="text" id="quarter" name="quarter" />
              </div>
              <div className="form-group">
                <label htmlFor="venue">Venue</label>
                <input onChange={handleInput} value={report.venue} type="text" id="venue" name="venue" />
              </div>
              <div className="form-group">
                <label htmlFor="speaker">Speaker</label>
                <input onChange={handleInput} value={report.speaker} type="text" id="speaker" name="speaker" />
              </div>
              <div className="form-group">
                <label htmlFor="coordinator">Coordinator</label>
                <input onChange={handleInput} value={report.coordinator} type="text" id="coordinator" name="coordinator" />
              </div>
              <div className="form-group">
                <label htmlFor="target_aud">Target Audience</label>
                <input onChange={handleInput} value={report.target_aud} type="text" id="target_aud" name="target_aud" />
              </div>
              <div className="form-group">
                <label htmlFor="attend">Attendance</label>
                <input onChange={handleInput} value={report.attend} type="text" id="attend" name="attend" />
              </div>
              <div className="form-group">
                <label htmlFor="expenditure">Expenditure</label>
                <input onChange={handleInput} value={report.expenditure} type="text" id="expenditure" name="expenditure" />
              </div>
              <div className="form-group">
                <label htmlFor="description">Description</label>
                <textarea onChange={handleInput} value={report.description} id="description" name="description"></textarea>
              </div>
              {/* <div className="form-group">
                <label htmlFor="info">Info</label>
                <textarea onChange={handleInput} value={report.info} id="info" name="info"></textarea>
              </div> */}
              <div className="form-group">
                <label htmlFor="fb">Facebook Link</label>
                <input onChange={handleInput} value={report.fb} type="text" id="fb" name="fb" />
              </div>
              <div className="form-group">
                <label htmlFor="lk">LinkedIn Link</label>
                <input onChange={handleInput} value={report.lk} type="text" id="lk" name="lk" />
              </div>
              <div className="form-group">
                <label htmlFor="ig">Instagram Link</label>
                <input onChange={handleInput} value={report.ig} type="text" id="ig" name="ig" />
              </div>
              <div className="form-group">
                <label htmlFor="yt">YouTube Link</label>
                <input onChange={handleInput} value={report.yt} type="text" id="yt" name="yt" />
              </div>
              <div className="form-group">
                <label htmlFor="tw">Twitter Link</label>
                <input onChange={handleInput} value={report.tw} type="text" id="tw" name="tw" />
              </div>
            </div>
            <div className="section">
              <h3>UPLOAD IMAGES</h3>
              <div className="form-group">
                <label htmlFor="img1">Image 1</label>
                <input type="file" name="img1" onChange={handleFileInput} />
              </div>
              <div className="form-group">
                <label htmlFor="img2">Image 2</label>
                <input type="file" name="img2" onChange={handleFileInput} />
              </div>
              <div className="form-group">
                <label htmlFor="img3">Image 3</label>
                <input type="file" name="img3" onChange={handleFileInput} />
              </div>
            </div>
            <button type="submit">Update Report</button>
          </form>
          {statusMessage && <p className="status-message">{statusMessage}</p>}
        </div>
      </div>
    </div>
  );
};

export default UpdateReport;
