import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AddCommitteMember.css';


const AddCommitteeMember = () => {
    const navigate = useNavigate();

    const [statusMessage, setStatusMessage] = useState('');
    const [image, setImage] = useState(null);
    const [report, setReport] = useState({
        c_name: '',
        c_email: '',
        c_pass: '',
        c_designation: ''
    });

    

    const handleInput = (e) => {
        const { name, value } = e.target;
        setReport({ ...report, [name]: value });
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        setImage(file);
    };

    const postReport = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('image', image);
        formData.append('memberData', JSON.stringify(report));

        try {
            // console.log(formData)
            const res = await fetch('/api/committeeMembers/addMember', {
                method: 'POST',
                body: formData,
                credentials: 'include'
            });

            if (res.ok) {
                // Member added successfully
                setStatusMessage('Member added successfully');
            } else {
                // Handle error
                setStatusMessage('Failed to add member');
            }
        } catch (error) {
            setStatusMessage('Error adding member');
            console.error('Error adding member:', error);
        }
    };

    return (
        <div className="add-member-container">
           
            <form onSubmit={postReport}>
            <h2 className='add-member-heading'>Add Member</h2>
                <div className="section">
                    
                    <div className="form-group">
                        <label htmlFor="c_name">Name</label>
                        <input onChange={handleInput} value={report.c_name} type="text" id="c_name" name="c_name" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="c_email">Email</label>
                        <input onChange={handleInput} value={report.c_email} type="email" id="c_email" name="c_email" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="c_pass">Password</label>
                        <input onChange={handleInput} value={report.c_pass} type="text" id="c_pass" name="c_pass" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="c_designation">Designation</label>
                        <input onChange={handleInput} value={report.c_designation} type="text" id="c_designation" name="c_designation" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="image">Image</label>
                        <input onChange={handleImageUpload} type="file" id="image-add-committee" name="image" accept="image/*" />
                    </div>
                </div>
                <button type="submit">Submit</button>
                {statusMessage && <p className="status-message">{statusMessage}</p>}
            </form>
           
        </div>
    );
};

export default AddCommitteeMember;
