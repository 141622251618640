import React, { useState, useEffect } from "react";
import axios from "axios";
import "./committeemember.css";
import UpdateCommitteeMember from "./UpdateCommitteeMember";
import { useNavigate } from "react-router-dom";

import Header from "./header";
import Footer from "./footer";

const CommitteeMember = () => {
  const usenavigate = useNavigate();
  const [member, setmember] = useState([]);
  const [filter, setFilter] = useState({
    c_name: "",
    c_email: "",
    c_designation: "",
  });

  useEffect(() => {
    fetchmember();
  }, [filter]);

  const fetchmember = () => {
    axios
      .get("/api/committeeMembers/getMember", {
        params: filter,
      })
      .then((response) => {
        setmember(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching member:", error);
      });
  };

  const deletemember = (memberid) => {
    axios
      .delete(
        `/api/committeeMembers/deleteMember/${memberid}`
      )
      .then((response) => {
        // console.log(response.data);
        fetchmember();
      })
      .catch((error) => {
        console.error("Error deleting report:", error);
      });
  };

  const updatemember = (memberid) => {
    axios
      .put(
        `/api/committeeMembers/updateMember/${memberid}`
      )
      .then((response) => {
        // console.log(response.data);
      })
      .catch((error) => {
        console.error("Error deleting report:", error);
      });
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  const handleUpdateClick = (member) => {
    // console.log("Update clicked for member:", member);
    usenavigate(`/UpdateCommitteeMember/${member._id}`);
  };

  const handleDeleteClick = (member) => {
    if (window.confirm("Are you sure you want to delete this member?")) {
      deletemember(member._id);
    }
  };

  return (
    <div>

      
      <div className="container-committee-member" style={{ padding: "50px" }}>
        
        <h1 className="title-cm">MEMBERS</h1>

        {/* Search Filters */}
        <div className="search-filters-cm" style={{ padding: "50px" }}>
          <input
            type="text"
            placeholder="Name"
            name="c_name"
            value={filter.c_name}
            onChange={handleFilterChange}
          />
          <input
            type="text"
            placeholder="Email ID"
            name="c_email"
            value={filter.c_email}
            onChange={handleFilterChange}
          />
          <input
            type="text"
            placeholder="Designation"
            name="c_designation"
            value={filter.c_designation}
            onChange={handleFilterChange}
          />
        </div>

        <div className="row-cm">
          {member.map((member) => (
            <div key={member._id} className="col-md-4 mb-4">
              <div className="card-cm shadow-sm">
                <div className="card-cm-body">
                  <img src={`/uploads/${member.c_photo}`} alt="img"/>
                  {/* <img src={require("../assets/aim.png")} alt="Attachment Icon" /> */}
                  <h5 className="card-cm-title">{member.c_name}</h5>
                  <p className="card-cm-text">{member.c_email}</p>
                  <p className="card-cm-text" style={{ fontStyle: "italic" }}>
                    {member.c_designation}
                  </p>

                  <div>
                    <button className="button-cm" onClick={() => handleUpdateClick(member)}>
                      Update
                    </button>
                    <button className="button-cm" onClick={() => handleDeleteClick(member)}>
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CommitteeMember;
