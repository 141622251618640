import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./Addreport.css";
import axios from "axios";

const UpdateCommitteeMember = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [statusMessage, setStatusMessage] = useState("");
  const [member, setMember] = useState({
    c_name: "",
    c_email: "",
    c_pass: "",
    c_designation: "",
  });

  useEffect(() => {
    fetchMember();
  }, [id]);

  const fetchMember = () => {
    axios
      .get(`/api/committeeMembers/getSingleMember/${id}`)
      .then((response) => {
        const memberData = response.data.myData;
        setMember({
          c_name: memberData.c_name,
          c_email: memberData.c_email,
          c_pass: memberData.c_pass,
          c_designation: memberData.c_designation,
        });
      })
      .catch((error) => {
        console.error("Error fetching member:", error);
      });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setMember({ ...member, [name]: value });
  };

  const updateMember = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.put(
        `/api/committeeMembers/updateMember/${id}`,
        member
      );

      if (res.status === 200) {
        setStatusMessage("Member updated successfully");
      } else {
        setStatusMessage("Failed to update member");
      }
    } catch (error) {
      setStatusMessage("Error updating member");
      console.error("Error updating member:", error);
    }
  };

  return (
    <div className="add-report-container">
      <h2>Update Member</h2>
      <form onSubmit={updateMember}>
        <div className="section">
          <h3>BASIC INFO</h3>
          <div className="form-row">
            <div className="form-group">
              <label className="basic-info" htmlFor="c_name">Name:</label>
              <input
                onChange={handleInput}
                value={member.c_name}
                type="text"
                id="c_name"
                name="c_name"
                required
              />
            </div>
            <div className="form-group">
              <label className="basic-info" htmlFor="c_email">Email:</label>
              <input
                onChange={handleInput}
                value={member.c_email}
                type="email"
                id="c_email"
                name="c_email"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label className="basic-info" htmlFor="c_pass">Password:</label>
              <input
                onChange={handleInput}
                value={member.c_pass}
                type="password"
                id="c_pass"
                name="c_pass"
              />
            </div>
            <div className="form-group">
              <label className="basic-info" htmlFor="c_designation">Designation:</label>
              <input
                onChange={handleInput}
                value={member.c_designation}
                type="text"
                id="c_designation"
                name="c_designation"
              />
            </div>
          </div>
        </div>
        <button className="update-member-btn" type="submit">Update Member</button>
      </form>
      {statusMessage && <p className="status-message">{statusMessage}</p>}
    </div>
  );
};

export default UpdateCommitteeMember;
