// Report.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Dashboard.css';
import PDFGenerator from "./GeneratePDF";
import ReactDOM from 'react-dom';

import Header from './header';
import Footer from './footer';
import CreatePDF from './Createpdf';
import { useNavigate } from 'react-router-dom';

const AdminReports = (props) => {
  const [reports, setReports] = useState([]);
  const [committeeNames, setCommitteeNames] = useState([]);

  const [filter, setFilter] = useState({
    keyword: '',
    c_name: '',
    startDate: '',
    endDate: '',
  });
  const [triggerDownloads, setTriggerDownloads] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    fetchReports();
    fetchCommitteeNames();
  }, [filter]);

  const fetchReports = () => {
    axios.get('/api/reports/getAllReports', {
      params: filter,
    })
      .then(response => {
        setReports(response.data);
      })
      .catch(error => {
        console.error('Error fetching reports:', error);
      });
  };

  const fetchCommitteeNames = () => {
    axios
      .get("/api/committee/getCommittee")
      .then((response) => {
        setCommitteeNames(response.data);
      })
      .catch((error) => {
        console.error("Error fetching committee names:", error);
      });
  };

  const deleteReport = (reportId) => {
    axios.delete(`/api/delete_reports/delete-reports/${reportId}`)
      .then(response => {
        // Handle the response if needed
        // console.log(response.data);
        fetchReports();
      })
      .catch(error => {
        console.error('Error deleting report:', error);
      });
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilter(prevFilter => ({
      ...prevFilter,
      [name]: value,
    }));
  };


  const handleDownloadClick = (report) => {
    // if (report.verification1 && report.verification2 && report.verification3) {
      // Generate PDF and display in a new window
      const pdfData = <PDFGenerator reportData={report} />;
      const pdfWindow = window.open("", "_blank");
      pdfWindow.document.write("<html><head><title>Event Summary Report</title></head><body>");
      pdfWindow.document.write("<div id='root'></div>");
      pdfWindow.document.write("</body></html>");
      ReactDOM.render(pdfData, pdfWindow.document.getElementById("root"));
    // } else {
    //   alert("Report not yet verified");
    //}
  };

  const handleUpdateClick = (report) => {
    // Placeholder for update functionality
    navigate(`/UpdateReport/${report._id}`);
    // console.log('Update clicked for report:', report);
  };

  const handleDeleteClick = (report) => {
    if (window.confirm('Are you sure you want to delete this report?')) {
      deleteReport(report._id);
    }
  };



  return (
    <div>
      <div className="container-dashboard-reports">
        <h1 className="title">Latest Events {props.username}</h1>

        {/* Search Filters */}
        <div className="search-filters" style={{ padding: "50px" }} >
          <input
            type="text"
            placeholder="Keyword"
            name="keyword"
            value={filter.keyword}
            onChange={handleFilterChange}
          />

          <select
            value={filter.c_name}
            name="c_name"
            onChange={handleFilterChange}
          >
            <option value="">Select Committee</option>
            {committeeNames.name &&
              committeeNames.name.map((name, index) => (
                <option key={index} value={name}>
                  {name}
                </option>
              ))}
          </select>


          <input
            type="date"
            placeholder="Start Date"
            name="startDate"
            value={filter.startDate}
            onChange={handleFilterChange}
          />
          <input
            type="date"
            placeholder="End Date"
            name="endDate"
            value={filter.endDate}
            onChange={handleFilterChange}
          />
        </div>

        {/* Reports Table */}
        <table className="dashboard-report-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Committee</th>
              <th>Date</th>
              <th>Type</th>
              <th>Quarter</th>
              <th>Venue</th>
              <th>Action</th>
              <th>Verification</th>



            </tr>
          </thead>
          <tbody>
            {reports.map((report) => (
              <tr key={report._id}>
                <td>{report.name}</td>
                <td>{report.c_name}</td>
                <td>{report.date}</td>
                <td>{report.type}</td>
                <td>{report.quarter}</td>
                <td>{report.venue}</td>
                <td>
                  <img
                    src={require("../assets/download.png")}
                    alt="Download"
                    className='dashboard-download'
                    onClick={() => handleDownloadClick(report)}

                  />
                  <img
                    src={require("../assets/update.png")}
                    alt="Update"
                    className='dashboard-update'
                    onClick={() => handleUpdateClick(report)}

                  />
                  <img
                    src={require("../assets/delete.png")}
                    alt="Delete"
                    className='dashboard-download'
                    onClick={() => handleDeleteClick(report)}

                  />

                </td>
                <td><div className="dashboard-verification-container">
  {report.verification1 ? (
    <div className="tooltip-container">
      <img
        src={require("../assets/verified.png")}
        alt="Verified"
        className='dashboard-download'
      />
      <span className="tooltip-text">{report.approver1}</span>
    </div>
  ) : (
    <div className="tooltip-container">
      <img
        src={require("../assets/not_verified.png")}
        alt="Not Verified"
        className='dashboard-download'
      />
      <span className="tooltip-text">{report.approver1}</span>
    </div>
  )}

{report.verification1 ? (
    <div className="tooltip-container">
      <img
        src={require("../assets/verified.png")}
        alt="Verified"
        className='dashboard-download'
      />
      <span className="tooltip-text">{report.approver2}</span>
    </div>
  ) : (
    <div className="tooltip-container">
      <img
        src={require("../assets/not_verified.png")}
        alt="Not Verified"
        className='dashboard-download'
      />
      <span className="tooltip-text">{report.approver2}</span>
    </div>
  )}

{report.verification1 ? (
    <div className="tooltip-container">
      <img
        src={require("../assets/verified.png")}
        alt="Verified"
        className='dashboard-download'
      />
      <span className="tooltip-text">{report.approver3}</span>
    </div>
  ) : (
    <div className="tooltip-container">
      <img
        src={require("../assets/not_verified.png")}
        alt="Not Verified"
        className='dashboard-download'
      />
      <span className="tooltip-text">{report.approver3}</span>
    </div>
  )}
</div>

                </td>

                


              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminReports;