import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "./header";
import Footer from "./footer";
import "./home.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';




const Home = () => {
  const [member, setmember] = useState([]);
  const [partner, setpartner] = useState([]);


  useEffect(() => {
    fetchmember();
  }, []);

  useEffect(() => {
    fetchpartner();
  }, []);


  //fetching data

  const fetchmember = () => {
    axios
      .get("/api/committeeMembers/getMember", {
      })
      .then((response) => {
        setmember(response.data);
      })
      .catch((error) => {
        console.error("Error fetching member:", error);
      });
  };

  const fetchpartner = () => {
    axios.get("/api/assoc_partners", {

    })
      .then((response) => {
        setpartner(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching member:", error);
      });
  };

  return (
    <div id="">
      <Header />



      <Carousel pause='false' >
        <Carousel.Item interval={4000}>
          <img
            src={require("../assets/iic_banner.png")}
            alt="banner"
          />
          <Carousel.Caption>

          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={4000}>
          <img
            src={require("../assets/group.jpeg")}
            alt="banner"
          />
          <Carousel.Caption>

          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={4000}>
          <img
            src={require("../assets/trial.jpeg")}
            alt="banner"
          />
          <Carousel.Caption>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>



      {/* <div className="institution-text">
        <h1>INSTITUTION'S</h1>
        <h1>INNOVATION</h1>
        <h1>COUNCIL</h1>
        <p>FOSTERING A CULTURE OF INNOVATION AND PROGRESS</p>
      </div>
      <img
        src={require("../assets/main_page_banner.jpg")}
        className="img-fluid banner-image"
        alt="banner"
      /> */}



      <Container fluid className='overview-cards'>
        <div className="container-cards">
          <div className="row-cards">
            <div className="col-lg-12">
              <h2 className="overviewTitle">IIC SPIT</h2>
            </div>
          </div>
        </div>
        <Row >
          <Col sm={12} lg={4} xl={4} md={4} className="">
            < div className="box-content blue-bg card-shadow overview-column">
              <span className="icons c1 icon-bg">
                <img src={require("../assets/aim.png")} alt="Attachment Icon" />
              </span>
              <div className="box-area">
                <h3>AIM</h3>
                <p>
                  IIC SPIT aims to foster a culture of innovation in students by
                  conducting various activities.
                </p>
              </div>
            </div>
          </Col>
          <Col sm={12} lg={4} xl={4} md={5}>
            <div className="box-content focus blue-bg card-shadow  overview-column">
              <span className="icons c1 icon-bg">
                <img
                  src={require("../assets//wheel.png")}
                  alt="Attachment Icon"
                />
              </span>
              <div className="box-area">
                <h3>MISSION</h3>
                <p>
                  IIC promotes innovation in the campus, aiming to create a
                  vibrant local innovation ecosystem.
                </p>
              </div>
            </div>
          </Col>
          <Col sm={12} lg={4} xl={4} md={4}>
            <div className="box-content blue-bg card-shadow overview-column">
              <span className="icons c1 icon-bg">
                <img
                  src={require("../assets/react.png")}
                  alt="Attachment Icon"
                />
              </span>
              <div className="box-area">
                <h3>OVERVIEW</h3>
                <p>
                  The primary mandate of IIC is to encourage, inspire, and
                  nurture young students
                </p>
              </div>
            </div>

          </Col>
        </Row>
      </Container>





      <section id="aboutus-headline">
        <div className="container">
          <h2 className="pageTitle">ABOUT US</h2>
        </div>
      </section>

      <section id="content">
        <div className="container">
          <Container fluid className="container-aboutus">
            {/* Showcase Section */}
            <Row className="showcase-section">
              {/* Image */}
              <Col md={6} sm={12} className="aboutus-image">
                <img
                  className="aboutus-image"
                  src={require("../assets/spit.jpg")}
                  alt="showcase"
                />
              </Col>
              {/* Text */}
              <Col md={6} sm={12} className="wow fadeInRight animated" data-wow-delay="2s" data-wow-duration="2s">
                <div className="about-text">
                  <h3>IIC</h3>
                  <p>
                    Ministry of HRD has established ‘MHRD’s Innovation Cell’ with the mandate to work closely with our Higher Education Institutions (HEIs) to encourage the creative energy of our student population to work on new ideas and innovation and promote them to create start-ups and entrepreneurial ventures.
                  </p>
                  <br />
                  <h3>IIC SPIT</h3>
                  <p>
                    IIC SPIT focuses on creating a complete ecosystem that will foster the culture of Innovation across the institution from ideas generation to pre-incubation, incubation, and graduating from the incubator as successful start-ups.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>










          {/* Mission and Vision Section */}


          <div className="container" id="mission-vision">

            <Container fluid className="container-aboutus">

              <Row className="showcase-section">

                <Col md={6} sm={12} >
                  <div className="mission-vision">
                    <h3>MAJOR FOCUS</h3>
                    <ul>
                      <li>To create a vibrant local innovation ecosystem.</li>
                      <li>
                        Establish Function Ecosystem for Scouting Ideas and
                        Pre-incubation of Ideas.
                      </li>
                      <li>Opportunity to nurture and prototype new ideas.</li>
                      <li>
                        Develop better Cognitive Ability for Technology Students.
                      </li>
                    </ul>
                    <br />
                    <h3>FUNCTIONS</h3>
                    <ul>
                      <li>
                        To conduct various innovation and entrepreneurship-related
                        activities prescribed by Central MIC in time-bound
                        fashion.
                      </li>
                      <li>
                        Identify and reward innovations and share success stories.
                      </li>
                      <li>
                        Organize periodic workshops/seminars/interactions with
                        entrepreneurs, investors, professionals, and create a
                        mentor pool for student innovators.
                      </li>
                    </ul>

                  </div>
                </Col>
                <Col md={6} sm={12} className="certificate-image">
                  <img
                    className="aboutus-image"
                    src={require("../assets/certificate.jpeg")}
                    alt="showcase"
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </div>





        {/* Team Section */}

        <section id="team-headline">

          <h2 className="pageTitle">Team</h2>

        </section>


        <div className="container team">
          <div id="team-row" className="team-row">

            <div
              className="team-image"
            >
              <img
                src={require("../assets/group.jpeg")}

                alt="TEAM IMG"
                className="img-fluid"
              />
            </div>

            {/* <section id="team-headline">
                    <div className="container">
                   <h2 className="pageTitle">Members</h2>
                        </div>
                    </section> */}

            {/* Members */}
            {/* Displaying Members */}
            <section id="members">
              <Container fluid className="">
                <Row>
                  {member && Array.isArray(member) && member.map((individualMember) => (
                    <Col xl={3} lg={3} md={4} sm={6} xs={6} key={individualMember.id}>
                      <div className="team-member">
                        <img
                          className="img"
                          src={`/uploads/${individualMember.c_photo}`}
                          alt={individualMember.c_name}
                          height="270px"
                          width="270px"
                        />
                        <div className="member-description">
                          <h4>{individualMember.c_name}</h4>
                          <span className="deg">{individualMember.c_designation}</span>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Container>
            </section>



          </div>
        </div>

        <section className="section-padding gray-bg">
          <div className="container" id="partners">
            <div className="partner-heading">
              <h3>ASSOCIATE PARTNERS</h3>
            </div>
            <Row>
              {partner.map((partner) => (
                <Col xl={3} lg={3} md={4} sm={6} xs={6} key={partner.id}>
                  <div className="team-member assoc-img">
                    <a href={`${partner.link}`}>
                      <img
                        className="img"
                        src={`/uploads/${partner.image}`}
                        alt={partner.name}
                      />
                    </a>
                    <div className="member-description associate-description">
                      <h4>{partner.name}</h4>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </section>

        {/* </div> */}
      </section>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default Home;