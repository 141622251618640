import React from 'react';
import "./footer.css"; // Import your custom CSS file

const Footer = () => {
  return (
    <div id="sub-footer" style={{backgroundColor: 'black', color: 'white', textAlign:'center', position: "relative", bottom: 0, width: '100%' }}>
      <div className="container text-centre">
        <div className="row">
          <div className="col-lg-12">
            <div className="copyright">
              <p>
                <span>
                  &copy; IIC SPIT 2023. All right reserved.
                  <br />
                  Developed by{' '}
                  <a href="https://www.linkedin.com/in/lokeshch18/" target="_blank" rel="noopener noreferrer">
                    Lokesh Chaudhari
                  </a>{', '}
                  {' '}
                  <a href="https://www.linkedin.com/in/prasad-chopade-0ba949258/" target="_blank" rel="noopener noreferrer">
                    Prasad Chopade
                  </a>{' '}
                  and{' '}
                  <a href="https://www.linkedin.com/in/paarth-bahety-871a2b212/" target="_blank" rel="noopener noreferrer">
                    Paarth Bahety
                  </a>
                </span>
              </p>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Footer;
