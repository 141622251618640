import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './header.css';

const Header = () => {
  return (
    <header>
      <div className="header-container">
        <img className="navbar-image" src={require("../assets/iic_logo.png")} alt="iic spit" />

        <div className="header-text">
          <p className="margin-zero mb-0">Bharatiya Vidya Bhavans</p>
          <h2 className="margin-zero mb-0">
            <span style={{ fontWeight: 'bold' }}>Sardar Patel Institute of Technology</span>
          </h2>
          <p className="margin-zero mb-0" style={{ color: '#aaa' }}>Autonomous Institute Affiliated to University of Mumbai</p>
          <p className="margin-zero mb-0"><span className="label label-default">IIC ID:</span> IC201811085</p>
        </div>
        
        <img className="navbar-image2" src={require("../assets/spit_logo.png")} alt="spit" />
      </div>

      <Navbar expand="lg" bg="dark" variant="dark" className="navbar-custom">
        <div className="container-fluid">
          <Navbar.Brand href="">
            {' IIC'}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarNavDropdown" />
          <Navbar.Collapse id="navbarNavDropdown">
            <Nav className="ml-auto">
              <Nav.Link as={Link} to='/'>Home</Nav.Link>
              <Nav.Link as={Link} to="/Contact">Contact Us</Nav.Link>
              <Nav.Link as={Link} to='/Gallery'>Gallery</Nav.Link>
              <Nav.Link as={Link} to="/Login">Login</Nav.Link>
              <Nav.Link as={Link} to="/FacultyLogin">Faculty Login</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </header>
  );
};

export default Header;
