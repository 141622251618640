import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Dashboard.css';
import Header from './header';
import Footer from './footer';
import PDFGenerator from "./GeneratePDF";
import ReactDOM from 'react-dom';

const VerifyReport = (props) => {
  const [reports, setReports] = useState([]);
  const [filter, setFilter] = useState({
    keyword: '',
    c_name: '',
    startDate: '',
    endDate: '',
  });
  const [committeeNames, setCommitteeNames] = useState([]);

  useEffect(() => {
    fetchReports();
    fetchCommitteeNames();
  }, [filter]);

  const [triggerDownloads, setTriggerDownloads] = useState({});

  const fetchCommitteeNames = () => {
    axios
      .get("/api/committee/getCommittee")
      .then((response) => {
        setCommitteeNames(response.data);
      })
      .catch((error) => {
        console.error("Error fetching committee names:", error);
      });
  };

  const fetchReports = () => {
    axios.get('/api/reports/getAllreports', {
      params: { ...filter, approver1: props.c_email, approver2: props.c_email, approver3: props.c_email, }
    })
      .then(response => {
        setReports(response.data);
      })
      .catch(error => {
        console.error('Error fetching reports:', error);
      });
  };

  const updateReport = (reportId) => {
    axios.put(`/api/verify_reports/verify-reports/${reportId}/${props.c_email}`)
      .then(response => {
        // Handle the response if needed
        // console.log(response.data);

        // Assuming you want to refresh the reports after verification
        fetchReports();
      })
      .catch(error => {
        console.error('Error updating report:', error);
      });
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilter(prevFilter => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  // ...
  const handleDownloadClick = (report) => {
    if (report.verification1 && report.verification2 && report.verification3) {
      // Generate PDF and display in a new window
      const pdfData = <PDFGenerator reportData={report} />;
      const pdfWindow = window.open("", "_blank");
      pdfWindow.document.write("<html><head><title>Event Summary Report</title></head><body>");
      pdfWindow.document.write("<div id='root'></div>");
      pdfWindow.document.write("</body></html>");
      ReactDOM.render(pdfData, pdfWindow.document.getElementById("root"));
    } else {
      alert("Report not yet verified");
    }
  };


  return (
    <div>
      <div className="container-dashboard-reports">

        <div className="search-filters" style={{ padding: "50px" }}>
          <input
            type="text"
            placeholder="Keyword"
            name="keyword"
            value={filter.keyword}
            onChange={handleFilterChange}
          />
          <select
              value={filter.c_name}
              name="c_name"
              onChange={handleFilterChange}
            >
              <option value="">Select Committee</option>
              {committeeNames.name &&
                committeeNames.name.map((name, index) => (
                  <option key={index} value={name}>
                    {name}
                  </option>
                ))}
            </select>
          <input
            type="date"
            placeholder="Start Date"
            name="startDate"
            value={filter.startDate}
            onChange={handleFilterChange}
          />
          <input
            type="date"
            placeholder="End Date"
            name="endDate"
            value={filter.endDate}
            onChange={handleFilterChange}
          />
        </div>


        <table className="dashboard-report-table">
          <thead>
            <tr>
              <th>Name</th>
              
              <th>Committee</th>
              <th>Venue</th>
              <th>Date</th>
              <th>View</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {reports.map(report => (
              <tr key={report._id}>
                <td>{report.name}</td>
                <td>{report.c_name}</td>
                <td id="report-description">{report.venue}</td>
                <td>{report.date}</td>
                <td>
                <div>
                <img
                    src={require("../assets/download.png")}
                    alt="Download"
                    className='dashboard-download'
                    onClick={() => handleDownloadClick(report)}

                  />

                  </div>

               
                </td>
                <td>
                  {(!report.verification1 && report.approver1 === props.c_email) ||
                    (!report.verification2 && report.approver2 === props.c_email) ||
                    (!report.verification3 && report.approver3 === props.c_email) ? (
                    <button
                      className="btn btn-primary"
                      onClick={() => updateReport(report._id)}
                    >
                      Approve
                    </button>
                  ) : (
                    <span> <img
                    src={require("../assets/verified.png")}
                    alt="Verified"
                    className='dashboard-download'
                    title='Approved'
                  /></span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

};

export default VerifyReport;