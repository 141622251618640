// Report.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './report.css';
import Header from './header';
import Footer from './footer';
import CreatePDF from './Createpdf';

const Report = () => {
  const [reports, setReports] = useState([]);
  const [filter, setFilter] = useState({
    keyword: '',
    c_name: '',
    startDate: '',
    endDate: '',
  });
  const [triggerDownloads, setTriggerDownloads] = useState({});

  useEffect(() => {
    fetchReports();
  }, [filter]);

  const fetchReports = () => {
    axios.get('/api/reports', {
      params: filter,
    })
      .then(response => {
        setReports(response.data);
      })
      .catch(error => {
        console.error('Error fetching reports:', error);
      });
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilter(prevFilter => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  const handleDownloadClick = (report) => {
    setTriggerDownloads(prevState => ({
      ...prevState,
      [report._id]: true,
    }));
  };

  return (
    <div>
      <Header />
      <div className="container">
        <h1 className="title">Latest Events Conducted By SPIT</h1>

        {/* Search Filters */}
        <div className="search-filters">
          <input
            type="text"
            placeholder="Keyword"
            name="keyword"
            value={filter.keyword}
            onChange={handleFilterChange}
          />
            <input
            type="text"
            placeholder="c_name"
            name="c_name"
            value={filter.c_name}
            onChange={handleFilterChange}
          />
            
          <input
            type="date"
            placeholder="Start Date"
            name="startDate"
            value={filter.startDate}
            onChange={handleFilterChange}
          />
          <input
            type="date"
            placeholder="End Date"
            name="endDate"
            value={filter.endDate}
            onChange={handleFilterChange}
          />
        </div>

        <div className="row">
          {reports.map(report => (
            <div key={report._id} className="col-md-6">
              <div className="card mb-4 shadow-sm">
                <div className="card-body">
                  <h5 className="card-title">{report.name}</h5>
                  <p className="card-text">{report.info}</p>
                  <div className="d-flex justify-content-between align-items-center">
                    <small className="text-muted">{report.date}</small>
                  </div>
                </div>
                <button onClick={() => handleDownloadClick(report)} style={{ backgroundColor: report.verification1 && report.verification2 && report.verification3 ? 'green' : 'red', color: 'white' }} disabled={!report.verification1 && !report.verification2 && !report.verification3 } >Download PDF</button>
                <CreatePDF reportData={report} triggerDownload={triggerDownloads[report._id]} />
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Report;
