
// Here we import all the webpages and css files

import './App.css';
import Home from './components/Home';
import Gallery from './components/Gallery';
import Report from './components/Report';
import Contact from './components/Contact';
import Login from './components/Login';
import AddReports from './components/AddReports';
import Dashboard from './components/Dashboard';
import AdminDashboard from './components/AdminDashboard';
import UpdateReport from './components/VerifyReport';
import FacultyLogin from './components/FacultyLogin';
import FacultyDashboard from './components/FacultyDashboard';
import UpdateCommitteeMember from './components/UpdateCommitteeMember';

// Importing react-router-dom module to route to diff pages
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';




// main function

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/Gallery' element={<Gallery />} />
          <Route exact path='/Contact' element={<Contact />} />
          <Route exact path='/Login' element={<Login />} />
          <Route exact path='/FacultyLogin' element={<FacultyLogin />} />
          <Route exact path='/Report' element={<Report />} />
          <Route exact path='/Dashboard' element={<Dashboard />} />
          <Route exact path='/AdminDashboard' element={<AdminDashboard />} />
          <Route exact path='/FacultyDashboard' element={<FacultyDashboard />} />
          <Route exact path='/UpdateReport/:id' element={<UpdateReport/>}/>
          
          <Route exact path='/UpdateCommitteeMember/:id' element={<UpdateCommitteeMember/>}/>
          {/* <Route exact path='/AddReports' element={<AddReports />} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
