import React from 'react';
import { PDFViewer, Document, Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';

const PDFGenerator = ({ reportData }) => {
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      padding: 30,
    },
    header: {
      marginBottom: 20,
      fontSize: 16,
      fontWeight: 'bold',
    },
    text: {
      marginBottom: 10,
      fontSize: 12,
    },
    signature: {
      fontSize: 12,
      marginBottom: 5,
    },
    image: {
      width: 200,
      height: 200,
      marginBottom: 20,
    },
  });

  return (
    <PDFViewer style={{ width: '100%', height: '100vh' }}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View>
            <Text style={styles.header}>Bhartiya Vidya Bhavans</Text>
            <Text style={styles.header}>Sardar Patel Institute of Technology</Text>
            <Text style={styles.header}>Bhavans Campus, Munshi Nagar, Andheri West, Mumbai, Maharashtra 400058</Text>
            <Text style={styles.header}>Event Summary Report</Text>

            <Text style={styles.text}>Program driven by: {reportData.c_name}</Text>
            <Text style={styles.text}>Duration of the Event: {reportData.duration}</Text>

            <Text style={styles.text}>Event Name: {reportData.name}</Text>
            <Text style={styles.text}>Attendance: {reportData.attend}</Text>
            <Text style={styles.text}>Venue: {reportData.venue}</Text>

            <Text style={styles.text}>Brief information about the event:</Text>
            <Text style={styles.text}>{reportData.info}</Text>
          </View>

          <View>
            
            <Image src={`/uploads/${reportData.img1}`} style={styles.image} />
            <Image src={`https://www.spit.ac.in/wp-content/uploads/2009/11/Entrance.jpg`} style={styles.image} />
            
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default PDFGenerator;
