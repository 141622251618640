import React, { useState, useEffect } from "react";
import axios from "axios";
import "./gallery.css";
import Header from "./header";
import Footer from "./footer";
import PDFGenerator from "./GeneratePDF";
import ReactDOM from 'react-dom';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';


const Gallery = () => {
  const [reports, setReports] = useState([]);
  const [filter, setFilter] = useState({
    keyword: "",
    c_name: "",
    startDate: "",
    endDate: "",
  });
  const [triggerDownloads, setTriggerDownloads] = useState({});
  const [visibleReports, setVisibleReports] = useState(10);
  const [committeeNames, setCommitteeNames] = useState([]);
  const [showPieChartButton, setShowPieChartButton] = useState(false);
  const [pieChartImage, setPieChartImage] = useState(null);

  useEffect(() => {
    fetchReports();
    fetchCommitteeNames();
  }, [filter]);

  const fetchReports = () => {
    axios
      .get("/api/reports/getAllReports", {
        params: filter,
      })
      .then((response) => {
        setReports(response.data);
      })
      .catch((error) => {
        console.error("Error fetching reports:", error);
      });
  };

  const fetchCommitteeNames = () => {
    axios
      .get("/api/committee/getCommittee")
      .then((response) => {
        setCommitteeNames(response.data);
      })
      .catch((error) => {
        console.error("Error fetching committee names:", error);
      });
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
    setShowPieChartButton(false);
  };

  const handleDownloadClick = (report) => {
    if (report.verification1 && report.verification2 && report.verification3) {
      const pdfData = <PDFGenerator reportData={report} />;
      const pdfWindow = window.open("", "_blank");
      pdfWindow.document.write("<html><head><title>Event Summary Report</title></head><body>");
      pdfWindow.document.write("<div id='root'></div>");
      pdfWindow.document.write("</body></html>");
      ReactDOM.render(pdfData, pdfWindow.document.getElementById("root"));
    } else {
      alert("Report not yet verified");
    }
  };

  const handleViewMore = () => {
    setVisibleReports((prevVisibleReports) => prevVisibleReports + 10);
  };

  const handlePieChartButtonClick = () => {
    if (showPieChartButton) {
      setShowPieChartButton(false);
      setPieChartImage(null);
    } else {
      setShowPieChartButton(true);
      axios
        .get(`/api/analysis/getpiechart/${filter.c_name}`)
        .then((response) => {
          setPieChartImage(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.error === "Committee not found") {
            alert("No pie chart available for this committee.");
          } else {
            console.error("Error fetching pie chart:", error);
          }
        });
    }
  };

  return (
    <div>
      <Header />
      <div className="gallery">
        <div className="container">
          <h1 className="title">Event Section</h1>

          <div className="search-filters">
            <input
              type="text"
              placeholder="enter event name"
              name="keyword"
              value={filter.keyword}
              onChange={handleFilterChange}
            />

            <select
              value={filter.c_name}
              name="c_name"
              onChange={handleFilterChange}
            >
              <option value="">Select Committee ...</option>
              {committeeNames.name &&
                committeeNames.name.map((name, index) => (
                  <option key={index} value={name}>
                    {name}
                  </option>
                ))}
            </select>

            {filter.c_name && (
              <img
                src={require("../assets/piechart.png")}
                alt="see analysis"
                onClick={handlePieChartButtonClick}
                title="Click to view analysis"
              />
            )}
          </div>

          {pieChartImage && (
            <div className="piechart-container">
              <img src={`/api/analysis/getpiechart/${filter.c_name}`} alt="Pie Chart" className="pie-chart-image" />
            </div>
          )}

         

<Container>
            <Row>
              {reports.slice(0, visibleReports).map((report) => (
                <Col xs={12} md={6} lg={4} key={report._id}>
                  <div className="report-container">
                    <Image
                      src={
                        report.img1
                          ? `/uploads/${report.img1}`
                          : require('../assets/image_not_availabe.png')
                      }
                      alt={report.name}
                      className="report-image"
                    />
                    {!triggerDownloads[report._id] && (
                      <button
                        className="view-report-btn"
                        onClick={() => handleDownloadClick(report)}
                      >
                        View Report
                      </button>
                    )}
                    <div className="report-name">{report.name}</div>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>


          {visibleReports < reports.length && (
            <div className="text-center">
              <button className="view-more-button" onClick={handleViewMore}>
                See More
              </button>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Gallery;
