import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Footer from './footer';
import AddReport from './AddReports';
import AdminReports from './AdminReports';
import './Dashboard.css';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import UpdateReport from './Updatereport';
import AddCommitteeMember from './AddCommitteMember';
import AddCommittee from './AddCommittee';
import CommitteeMember from './CommitteeMember';

const AdminDashboard = () => {
 
  const navigate = useNavigate();


  const [selectedMenuItem, setSelectedMenuItem] = useState('dashboard');

  const handleLogout = () => {
    sessionStorage.removeItem('authToken')
    navigate('/Login');
  };

  const handleMenuItemClick = (menuItem) => {
    setSelectedMenuItem(menuItem);
  };

  return (
    <>
      <div className="container text-center">
        <div className="row align-items-center">
          <div className="col-xs-3 col-md-3" style={{ paddingRight: '10px' }}>
            <div className="square" id="iic_logo">
              <img className="navbar-brand" style={{height:'80px',display:'block'}} src={require("../assets/iic_logo.png")} alt="iic spit" />
            </div>
          </div>

          <div className="col-xs-6 col-md-6" style={{ padding: '10px 20px' }}>
            <p className="margin-zero mb-0">Bharatiya Vidya Bhavans</p>
            <h2 className="margin-zero mb-0">
              <span style={{ fontWeight: 'bold' }}>Sardar Patel Institute of Technology</span>
            </h2>
            <p className="margin-zero mb-0" style={{ color: '#aaa' }}>Autonomous Institute Affiliated to University of Mumbai</p>
            <p className="margin-zero mb-0"><span className="label label-default">IIC ID:</span> IC201811085</p>
          </div>

          <div className="col-xs-3 col-md-3" style={{ padding: '20px 10px' }}>
            <img className=" navbar-image" style={{height:'100px'}} src={require("../assets/spit_logo.png")} alt="spit" />
          </div>
        </div>
      </div>

      <Navbar expand="lg" bg="dark" variant="dark">
        <div className="container-fluid">
          <Navbar.Brand as={Link} to="/">
            {' IIC'}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarNavDropdown" />
          <Navbar.Collapse id="navbarNavDropdown">
            <Nav className="navbar-nav ml-auto">
              <Nav.Link onClick={() => handleMenuItemClick('dashboard')}>
                Dashboard
              </Nav.Link>
              <Nav.Link onClick={() => handleMenuItemClick('addReports')}>
                Add Report
              </Nav.Link>
              <Nav.Link onClick={() => handleMenuItemClick('Faculty')}>
                Faculty
              </Nav.Link>
              <Nav.Link onClick={() => handleMenuItemClick('addCommittee')}>
                Committee
              </Nav.Link>

              <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>

      <div className="dashboard-container">
        
        {selectedMenuItem === 'dashboard' && <AdminReports/>}
        {selectedMenuItem === 'addReports' && <AddReport />}
        {selectedMenuItem === 'updateReports' && <UpdateReport />}
        {selectedMenuItem === 'Faculty' && <><AddCommitteeMember /><CommitteeMember /></>}
        {selectedMenuItem === 'addCommittee' && <AddCommittee />}
      </div>
      <footer>
        <Footer />
      </footer>
    </>
  );
};

export default AdminDashboard;
