import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Addreport.css';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles

const AddReport = () => {
  const navigate = useNavigate();
  const [statusMessage, setStatusMessage] = useState('');
  const [member, setMember] = useState([]);
  const [committeeNames, setCommitteeNames] = useState([]);
  const [report, setReport] = useState({
    name: '',
    c_name: '',
    date: '',
    duration: '',
    type: '',
    quarter: '',
    venue: '',
    speaker: '',
    coordinator: '',
    target_aud: '',
    attend: '',
    expenditure: '',
    description: '',
    info: '',
    fb: '',
    lk: '',
    ig: '',
    yt: '',
    tw: '',
    img1: null,
    img2: null,
    img3: null,
    approver1: '',
    approver2: '',
    approver3: ''
  });

  useEffect(() => {
    fetchMember();
    fetchCommitteeNames();
  }, []);

  const fetchMember = () => {
    axios.get('/api/committeeMembers/getMember')
      .then(response => {
        setMember(response.data);
      })
      .catch(error => {
        console.error('Error fetching member:', error);
      });
  };

  const fetchCommitteeNames = () => {
    axios.get("/api/committee/getCommittee")
      .then(response => {
        setCommitteeNames(response.data);
      })
      .catch(error => {
        console.error("Error fetching committee names:", error);
      });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setReport({ ...report, [name]: value });
  };

  const handleFileInput = (e) => {
    const { name, files } = e.target;
    if (files && files[0]) {
      const fileSize = files[0].size / 1024; // in KB
      if (fileSize > 250) {
        setStatusMessage('Please select an image file with size less than 250 KB.');
        return;
      }
      setReport({ ...report, [name]: files[0] });
    }
  };

  const handleDescriptionChange = (value) => {
    setReport({ ...report, description: value });
  };

  const postReport = async (e) => {
    e.preventDefault();
    if (!report.name || !report.date || !report.c_name || !report.duration || !report.type || !report.quarter || !report.venue || !report.speaker || !report.coordinator || !report.target_aud || !report.attend || !report.expenditure) {
      setStatusMessage('Please fill out all required fields');
      return;
    }
    const formData = new FormData();
    formData.append('reportData', JSON.stringify(report));
    formData.append('images', report.img1);
    formData.append('images', report.img2);
    formData.append('images', report.img3);

    try {
      const res = await fetch('/api/add_reports/addReport', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('authToken')}`,
        },
        credentials: 'include',
        body: formData,
      });

      if (res.ok) {
        setStatusMessage('Report added successfully');
      } else {
        setStatusMessage('Failed to add report');
      }
    } catch (error) {
      setStatusMessage('Error adding report');
      console.error('Error adding report:', error);
    }
  };

  return (
    <div className="addreports">
      <div className="section form-section">
        <div className="add-report-container">
          <h2>ADD REPORT</h2>
          <form onSubmit={postReport}>
            <div className="section">
              <br></br>
              <h3 style={{ textAlign: 'center' }}>BASIC INFO</h3>
              <br></br>
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="name">Event Name</label>
                  <input onChange={handleInput} value={report.name} type="text" id="name" name="name" />
                </div>
                <div className="form-group select-button">
                  <label htmlFor="c_name">Committee Name</label>
                  <select id='c_name' value={report.c_name} name="c_name" onChange={handleInput}>
                    <option value="">Select Committee</option>
                    {committeeNames.name && committeeNames.name.map((name, index) => (
                      <option key={index} value={name}>{name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="section">
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="date">Date</label>
                    <input onChange={handleInput} value={report.date} type="date" id="date" name="date" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="duration">Duration</label>
                    <input onChange={handleInput} value={report.duration} type="text" id="duration" name="duration" />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="type">Type</label>
                    <input onChange={handleInput} value={report.type} type="text" id="type" name="type" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="quarter">Quarter</label>
                    <input onChange={handleInput} value={report.quarter} type="text" id="quarter" name="quarter" />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="venue">Venue</label>
                    <input onChange={handleInput} value={report.venue} type="text" id="venue" name="venue" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="speaker">Speaker</label>
                    <input onChange={handleInput} value={report.speaker} type="text" id="speaker" name="speaker" />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="coordinator">Coordinator</label>
                    <input onChange={handleInput} value={report.coordinator} type="text" id="coordinator" name="coordinator" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="target_aud">Target Audience</label>
                    <input onChange={handleInput} value={report.target_aud} type="text" id="target_aud" name="target_aud" />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="attend">Attendees</label>
                    <input onChange={handleInput} value={report.attend} type="text" id="attend" name="attend" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="expenditure">Expenditure</label>
                    <input onChange={handleInput} value={report.expenditure} type="text" id="expenditure" name="expenditure" />
                  </div>
                </div>
                <div>
                  <label htmlFor="description">Description</label>
                  <ReactQuill value={report.description} onChange={handleDescriptionChange} />
                </div>
                <br></br>
                <h3 style={{ textAlign: 'center' }}>SOCIAL MEDIA</h3>
                <br></br>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="fb">Facebook</label>
                    <input onChange={handleInput} value={report.fb} type="text" id="fb" name="fb" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="lk">LinkedIn</label>
                    <input onChange={handleInput} value={report.lk} type="text" id="lk" name="lk" />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="ig">Instagram</label>
                    <input onChange={handleInput} value={report.ig} type="text" id="ig" name="ig" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="yt">YouTube</label>
                    <input onChange={handleInput} value={report.yt} type="text" id="yt" name="yt" />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="tw">Twitter</label>
                    <input onChange={handleInput} value={report.tw} type="text" id="tw" name="tw" />
                  </div>
                </div>
                <br></br>
                <h3 style={{ textAlign: 'center' }}>SIGNATURES</h3>
                <br></br>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="approver1">Committee Faculty Advisor / HOD</label>
                    <select name="approver1" onChange={handleInput} value={report.approver1}>
                      <option value="">Select faculty</option>
                      {member.map(member => (
                        <option key={member._id} value={member.c_email}>{member.c_name} ({member.c_email})</option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="approver2">Dean Student affairs</label>
                    <select name="approver2" onChange={handleInput} value={report.approver2}>
                      <option value="">Select faculty</option>
                      {member.map(member => (
                        <option key={member._id} value={member.c_email}>{member.c_name} ({member.c_email})</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="approver3">IIC President/Vice president/ Convener</label>
                    <select name="approver3" onChange={handleInput} value={report.approver3}>
                      <option value="">Select faculty</option>
                      {member.map(member => (
                        <option key={member._id} value={member.c_email}>{member.c_name} ({member.c_email})</option>
                      ))}
                    </select>
                  </div>
                </div>
                <br></br>
              </div>
              <div className="section">
                <h3 style={{ textAlign: 'center' }}>IMAGES</h3>
                <br></br>
                <div className="form-group">
                  <label htmlFor="img1">Cover Image</label>
                  <input type="file" name="img1" onChange={handleFileInput} />
                </div>
                <div className="form-group">
                  <label htmlFor="img2">Image</label>
                  <input type="file" name="img2" onChange={handleFileInput} />
                </div>
                <div className="form-group">
                  <label htmlFor="img3">Attendence</label>
                  <input type="file" name="img3" onChange={handleFileInput} />
                </div>
              </div>
              <button type="submit">Submit</button>
              </div>
            </form>
            {statusMessage && <p className="status-message">{statusMessage}</p>}
          </div>
        </div>
      </div>
    
  );
};

export default AddReport;
