import React from 'react';
import { PDFViewer, Document, Page, Text, View, Image, StyleSheet, Font } from '@react-pdf/renderer';
import { parseDocument } from 'htmlparser2';

const PDFGenerator = ({ reportData }) => {

  Font.register({
    family: 'Times-Bold',
    src: './public/timesbold.ttf',
    fontStyle: 'normal',
    fontWeight: 'bold',
  });

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      padding: 30,
      backgroundColor: '#f0f0f0',
    },
    header: {
      marginBottom: 10,
      fontSize: 14,
      fontWeight: 'bold',
      textAlign: 'center',
      color: 'black',
    },
    description: {
      marginBottom: 10,
      fontSize: 18,
      fontWeight: 'bold',
      textAlign: 'center',
      color: 'black',
    },
    text: {
      marginTop: 7,
      fontSize: 13,
      color: '#000000',
      fontWeight: 'bold',
    },
    htext: {
      fontWeight: 'bold',
      marginBottom: 4,
      fontSize: 12,
    },
    image: {
      marginTop: 10,
      marginRight: 10,
      width: '40%',
    },
    boldText: {
      marginTop: 5,
      marginBottom: 4,
      color: '#000000',
      fontSize: 14,
      fontFamily: 'Times-Bold'
    },
    headerimage: {
      marginTop: 4,
      marginBottom: 10,
      width: 100,
      height: 100,
      marginLeft: 'auto',
      marginRight: 'auto',
    }
  });

  const renderHtmlContent = (html) => {
    const dom = parseDocument(html);

    const renderNode = (node) => {
      if (node.type === 'text') {
        return <Text>{node.data}</Text>;
      }

      if (node.type === 'tag') {
        switch (node.name) {
          case 'strong':
            return <Text style={styles.boldText}>{node.children.map(renderNode)}</Text>;
          case 'em':
            return <Text style={{ fontStyle: 'italic' }}>{node.children.map(renderNode)}</Text>;
          case 'p':
            return <Text style={styles.text}>{node.children.map(renderNode)}</Text>;
          case 'ul':
            return <View style={{ marginLeft: 10 }}>{node.children.map(renderNode)}</View>;
          case 'li':
            return <Text style={styles.text}>{'\u2022 '}{node.children.map(renderNode)}</Text>;
          case 'ol':
            return <View style={{ marginLeft: 10 }}>{node.children.map(renderNode)}</View>;
          default:
            return node.children.map(renderNode);
        }
      }

      return null;
    };

    return dom.children.map(renderNode);
  };

  const generatePDF = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <Image src={`/uploads/spit_logo.jpeg`} style={styles.headerimage} />
          <Text style={styles.header}>Bhartiya Vidya Bhavans</Text>
          <Text style={styles.header}>Sardar Patel Institute of Technology</Text>
          <Text style={styles.header}>Bhavans Campus, Munshi Nagar, Andheri West, Mumbai, Maharashtra 400058</Text>
          <Text style={styles.header}>Event Summary Report</Text>
          <View style={{ flexDirection: 'row' }}>
            <Text style={[styles.text,]}>  </Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={[styles.boldText,]}>Event Name:</Text><Text style={styles.text}> {reportData.name}</Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={[styles.boldText]}>Program driven by:</Text><Text style={styles.text}> {reportData.c_name}</Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={[styles.text, styles.boldText]}>Date:</Text><Text style={styles.text}> {reportData.date}</Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={[styles.text, styles.boldText]}>Type:</Text><Text style={styles.text}> {reportData.type}</Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={[styles.text, styles.boldText]}>Quarter:</Text><Text style={styles.text}> {reportData.quarter}</Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={[styles.text, styles.boldText]}>Duration:</Text><Text style={styles.text}> {reportData.duration}</Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={[styles.text, styles.boldText]}>Venue:</Text><Text style={styles.text}> {reportData.venue}</Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={[styles.text, styles.boldText]}>Speaker:</Text><Text style={styles.text}> {reportData.speaker}</Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={[styles.text, styles.boldText]}>Coordinator:</Text><Text style={styles.text}> {reportData.coordinator}</Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={[styles.text, styles.boldText]}>Target Audience:</Text><Text style={styles.text}> {reportData.target_aud}</Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={[styles.text, styles.boldText]}>Attendance: </Text><Text style={styles.text}>{reportData.attend}</Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={[styles.text, styles.boldText]}>Expenditure: </Text><Text style={styles.text}>{reportData.expenditure}</Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={[styles.text,]}>  </Text>
          </View>
          
          <Text style={[styles.text, styles.boldText, styles.description]}>Description</Text>
          <View>
            {renderHtmlContent(reportData.description)}
          </View>
          
          <View style={{ flexDirection: 'row' }}>
            <Text style={[styles.text,]}>  </Text>
          </View>
          
          <Text style={[styles.text, styles.boldText, styles.description]}>Social Media</Text>
          <Text style={[styles.text, styles.boldText]}>Facebook: {reportData.fb}</Text>
          <Text style={[styles.text, styles.boldText]}>LinkedIn: {reportData.lk}</Text>
          <Text style={[styles.text, styles.boldText]}>Instagram: {reportData.ig}</Text>
          <Text style={[styles.text, styles.boldText]}>YouTube: {reportData.yt}</Text>
          <Text style={[styles.text, styles.boldText]}>Twitter: {reportData.tw}</Text>
          <View style={{ flexDirection: 'row' }}>
            <Text style={[styles.text,]}>  
              </Text>
          </View>

          <Text style={[styles.text, styles.boldText, styles.description]}>Image References</Text>
          {/* Adding images */}
          <Image src={`/uploads/${reportData.img1}`} style={styles.image} />
          <Image src={`/uploads/${reportData.img2}`} style={styles.image} />
          <Text style={[styles.text, styles.boldText, styles.description]}>Attendence Sheet</Text>
          <Image src={`/uploads/${reportData.img3}`} style={styles.image} />
          <View style={{ flexDirection: 'row' }}>
            <Text style={[styles.text,]}></Text></View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={[styles.text,]}></Text></View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={[styles.text,]}></Text></View>     
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <Text style={[styles.text, styles.boldText, styles.description]}>Signatures</Text>
        <Text style={[styles.text,]}></Text>
        <Text style={[styles.text,]}></Text>
        <View>
          <Text style={[styles.text, styles.boldText]} >1. Signature of Committee Faculty Advisor / HOD - </Text><Text style={styles.text}>verified by {reportData.approver1}</Text>
          <Text style={[styles.text,]}></Text>
          <Text style={[styles.text, styles.boldText]} >2. Signature of Student chairperson - </Text>
          <Text style={[styles.text,]}>verified</Text>
          <Text style={[styles.text,]}></Text>
          <Text style={[styles.text, styles.boldText]} >3. Signature of Dean Student affairs - </Text><Text style={styles.text}>verified by {reportData.approver2}</Text>
          <Text style={[styles.text,]}></Text>
          <Text style={[styles.text, styles.boldText]} >4. Signature of IIC President/Vice president/ Convener - </Text><Text style={styles.text}>verified by {reportData.approver3}</Text>
          <Text style={[styles.text,]}></Text>
          <Text style={[styles.text, styles.boldText]} >Time of reporting the event - {reportData.date}</Text>
        </View>
      </Page>
    </Document>
  );

  return (
    <div style={{ width: '100%', height: '100vh', display: 'full', justifyContent: 'center', alignItems: 'center' }}>
      <PDFViewer style={{ width: '100%', height: '100%' }}>
        {generatePDF()}
      </PDFViewer>
    </div>
  );
};

export default PDFGenerator;
