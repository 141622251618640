import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AddCommittee.css'

const AddCommittee = () => {
  const [committeeNames, setCommitteeNames] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    username: '',
    password: ''
  });

  const fetchCommitteeNames = () => {
    axios
      .get("/api/committee/getCommitteeInfo")
      .then((response) => {
        setCommitteeNames(response.data);
        // console.log(response.data)
      
      })
      .catch((error) => {
        console.error("Error fetching committee names:", error);
      });
  };

  
  useEffect(() => {
    fetchCommitteeNames();
  }, []); // Fetch committees on component mount

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleAddCommittee = async () => {
    try {
      await axios.post('/api/committee/addCommittee', formData); // Adjust the route accordingly
      fetchCommitteeNames(); // Refresh the committees list after adding
      setFormData({ name: '', username: '', password: '' }); // Clear the form data
    } catch (error) {
      console.error('Error adding committee:', error);
    }
  };

  // const deleteCommittee = async (id) => {
  //   try {
  //     await axios.delete(`/api/committee/deleteCommittee/${id}`); // Adjust the route accordingly
      
  //   } catch (error) {
  //     console.error('Error deleting committee:', error);
  //   }
  // };

  
  const deleteCommittee = (id) => {
    axios.delete(`/api/committee/deleteCommittee/${id}`)
      .then(response => {
        // Handle the response if needed
        // console.log(response.data);
        fetchCommitteeNames();
      })
      .catch(error => {
        console.error('Error deleting report:', error);
      });
  };


  const handleDeleteClick = (report) => {
    if (window.confirm('Are you sure you want to delete this report?')) {
      deleteCommittee(report._id);
    }
  };

  return (
    <div className='committees-container'>
      <h2>Add Committee</h2>
      <input type="text" name="name" placeholder="Name" value={formData.name} onChange={handleInputChange} />
      <input type="text" name="username" placeholder="Username" value={formData.username} onChange={handleInputChange} />
      <input type="text" name="password" placeholder="Password" value={formData.password} onChange={handleInputChange} />
      <button className='add-committee-button' onClick={handleAddCommittee}>Add Committee</button>
      <br></br>
      <h1>Committees</h1>
      
      {committeeNames &&
        committeeNames.map((committeeNames) => (
          <div key={committeeNames.id} class="committee-container">
            <div class="committee-name">{committeeNames.name}</div>
            
            <img
                    src={require("../assets/delete.png")}
                    alt="Delete"
                    className='dashboard-download'
                    onClick={() => handleDeleteClick(committeeNames)}

                  />
          </div>
            
      
        ))}
      
    </div>
  );
  
};

export default AddCommittee;
