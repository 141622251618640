import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import "./login.css"; // Import the CSS file
import { FaUser, FaLock } from 'react-icons/fa';
import { FormControl, InputGroup } from 'react-bootstrap';

const FacultyLogin = () => {
  const [formData, setFormData] = useState({
    c_email: "",
    password: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { c_email, password } = formData;

    try {
      const response = await fetch(
        "/api/auth/Facultylogin",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ c_email, password }),
        }
      );

      if (response.ok) {
        // Authentication successful, extract and store the token in a cookie
        const { token, name } = await response.json();
        sessionStorage.setItem('authToken',token);

        // Redirect to the desired page (e.g., '/AddReports')
        // console.log(c_email);
        navigate("/FacultyDashboard", { state: { name, c_email } });
      } else {
        // Authentication failed, handle error
        console.log("Login failed: Incorrect c_email or password");
        alert("Wrong credentials");
      }
    } catch (error) {
      console.error("Error during login:", error);
      alert("Error during login");
    }
  };

  return (<div>
    <Header />
  
      <Container fluid className="login-form">
        <Row>
          <Col  md={6} sm={3}className="image-container">
            <Image src={require("../assets/iic_logo.png")} alt="Attachment Icon" fluid />
          </Col>
          <Col md={6}>
            <div className="login-form-container">
              <h3>FacultyLogin</h3>

              <Form onSubmit={handleSubmit}>
                <InputGroup className="mb-3">
                  <InputGroup.Text>
                    <FaUser /> {/* Icon */}
                  </InputGroup.Text>
                  <FormControl
                    type="text"
                    placeholder="Enter username"
                    name="c_email"
                    value={formData.c_email}
                    onChange={handleChange}
                    style={{ backgroundColor: '#f0f0f0' }} // Grey background color
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text>
                    <FaLock /> {/* Icon */}
                  </InputGroup.Text>
                  <FormControl
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    style={{ backgroundColor: '#f0f0f0' }} // Grey background color
                  />
                </InputGroup>
                <div className="submit-button"> <Button variant="primary" type="submit" >
                LOGIN
              </Button></div>
              </Form>
             
            </div>
          </Col>
        </Row>
      </Container>

    <Footer />
  </div>
);
};

export default FacultyLogin;
