import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import "./login.css"; // Import the CSS file
import { FaUser, FaLock } from 'react-icons/fa';
import { FormControl, InputGroup } from 'react-bootstrap';

const Login = () => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { username, password } = formData;

    try {
      const response = await fetch("/api/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        // Authentication successful, extract and store the token in a cookie
        const { token, name } = await response.json();
        sessionStorage.setItem('authToken', token);

        // Redirect to the desired page (e.g., '/AddReports')
        if (username !== "admin") {
          navigate("/Dashboard", { state: { name } });

        } else {
          navigate("/AdminDashboard");
        }
      } else {
        // Authentication failed, handle error
        console.log("Login failed: Incorrect username or password");
        alert("Wrong credentials");
      }
    } catch (error) {
      console.error("Error during login:", error);
      alert("Error during login");
    }
  };

  return (
    <div>
      <Header />
    
        <Container fluid className="login-form">
          <Row>
            <Col  md={6} sm={3}className="image-container">
              <Image src={require("../assets/iic_logo.png")} alt="Attachment Icon" fluid />
            </Col>
            <Col md={6}>
              <div className="login-form-container">
                <h3>Login</h3>

                <Form onSubmit={handleSubmit}>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>
                      <FaUser /> {/* Icon */}
                    </InputGroup.Text>
                    <FormControl
                      type="text"
                      placeholder="Enter username"
                      name="username"
                      value={formData.username}
                      onChange={handleChange}
                      style={{ backgroundColor: '#f0f0f0' }} // Grey background color
                    />
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>
                      <FaLock /> {/* Icon */}
                    </InputGroup.Text>
                    <FormControl
                      type="password"
                      placeholder="Password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      style={{ backgroundColor: '#f0f0f0' }} // Grey background color
                    />
                  </InputGroup>
                  <div className="submit-button"> <Button variant="primary" type="submit" >
                  LOGIN
                </Button></div>
                </Form>
               
              </div>
            </Col>
          </Row>
        </Container>
  
      <Footer />
    </div>
  );
};

export default Login;
