
import React, { useState, useEffect } from 'react';
import Header from './header';
import Footer from './footer';
import axios from 'axios';
import { Container, Form, Button } from 'react-bootstrap';
import './contact.css'

const ContactCard = ({ contactInfo }) => {
  return (
    <div className="contact-card">
      <b>Institution's Innovation Council</b>
      <br />
      <p style={{ marginBottom: '0px' }}>Sardar Patel Institute of Technology</p>
      <p style={{ marginBottom: '0px' }}>
        <b>Address: </b>{contactInfo.address}
      </p>
      
      <p style={{ marginBottom: '0px' }}>
        <b>Phone: </b>{contactInfo.phone}
      </p>
      <p>
        <b>Email: </b>{contactInfo.email}
      </p>
    </div>
  );
};

const Contact = () => {
  const [contactInfo, setContactInfo] = useState({});

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios.get('/api/contactInfo')
      .then(response => {
        setContactInfo(response.data[0]);
        // console.log(response.data)
        // console.log(contactInfo)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  return (
    <div>
      <Header />
      <Container>
        <section id="inner-headline">
          <div className="container ">
            <div className="row">
              <div className="col-lg-12 " style={{ padding: '5px' }}>
                <br />
                <h2 className="pageTitle">Contact IIC SPIT</h2>
              </div>
            </div>
          </div>
        </section>

        <Container>
          <div className="row">
            <div className="col-md-12">
              <ContactCard contactInfo={contactInfo} />
            </div>
          </div>
        </Container>
<br/>
        <Container>
          <div className='row'>
            <div className="col-md-6" id="map-frame">
              <iframe
                title="IIC Location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.646049226283!2d72.83392671451583!3d19.12317758706089!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c9d90e067ba9%3A0x16268e5d6bca2e6a!2sBharatiya+Vidya+Bhavan's+Sardar+Patel+Institute+of+Technology!5e0!3m2!1sen!2sin!4v1516978145209"
                width="100%"
                height="350"
                style={{ border: '0' }}
                allowFullScreen=""
              ></iframe>
            </div>
            <Container>
              <br />
              <br />
              <br /><br />
            </Container>
          </div>
        </Container>
      </Container>
      <footer><Footer /></footer>
    </div>
  );
};

export default Contact;

